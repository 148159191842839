export default function Footer() {
  return (
    <>
      {/* <!-- Footer Start --> */}
      <div
        class="container-fluid bg-dark text-light footer pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Adres</h4>
              <p class="mb-2">
                <i class="fa fa-map-marker-alt me-3"></i>Fevzi Çakmak Mahallesi
                10618. Sokak No:32 A, Karatay/KONYA
              </p>
              <p class="mb-2">
                <i class="fa fa-phone-alt me-3"></i>+90 532 230 40 82
              </p>
              <p class="mb-2">
                <i class="fa fa-envelope me-3"></i>info@mkistif.com
              </p>
              <div class="d-flex pt-2">
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/mkistif?mibextid=ZbWKwL"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://instagram.com/mkistif?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Çalışma Saatleri</h4>
              <h6 class="text-light">Pazartesi - Cumartesi</h6>
              <p class="mb-4">08.00 - 19.00</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}
    </>
  );
}
