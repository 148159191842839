export default function Home() {
  return (
    <>
      {/* <!-- Carousel Start --> */}
      <div class="container-fluid p-0 mb-5">
        <div
          id="header-carousel"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="w-100" src="img/carousel-bg-1.png" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-6 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">
                        // ÜRÜNLERİMİZ //
                      </h6>
                      <h1 class="display-3 text-white mb-4 pb-3 animated slideInDown">
                        Sıfır ve İkinci El Forklift
                      </h1>
                      <a
                        href="service.html"
                        class="btn btn-primary py-3 px-5 animated slideInDown"
                      >
                        Detaylı BİLGİ<i class="fa fa-arrow-right ms-3"></i>
                      </a>
                    </div>
                    <div class="col-lg-6 d-none d-lg-flex animated zoomIn">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100" src="img/carousel-bg-2.png" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-7 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">
                        // ÜRÜNLERİMİZ //
                      </h6>
                      <h1 class="display-3 text-white mb-4 pb-3 animated slideInDown">
                        Forklift Ataşmanları
                      </h1>
                      <a
                        href="service.html"
                        class="btn btn-primary py-3 px-5 animated slideInDown"
                      >
                        Detaylı BİLGİ<i class="fa fa-arrow-right ms-3"></i>
                      </a>
                    </div>
                    <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                      <img class="img-fluid" src="img/carousel-2.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100" src="img/carousel-bg-3.png" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-7 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">
                        // ÜRÜNLERİMİZ //
                      </h6>
                      <h1 class="display-3 text-white mb-4 pb-3 animated slideInDown">
                        İstif Makinaları
                      </h1>
                      <a
                        href="service.html"
                        class="btn btn-primary py-3 px-5 animated slideInDown"
                      >
                        Detaylı BİLGİ<i class="fa fa-arrow-right ms-3"></i>
                      </a>
                    </div>
                    <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                      <img class="img-fluid" src="img/carousel-3.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100" src="img/carousel-bg-4.png" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-7 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">
                        // ÜRÜNLERİMİZ //
                      </h6>
                      <h1 class="display-3 text-white mb-4 pb-3 animated slideInDown">
                        Platformlar
                      </h1>
                      <a
                        href="service.html"
                        class="btn btn-primary py-3 px-5 animated slideInDown"
                      >
                        Detaylı BİLGİ<i class="fa fa-arrow-right ms-3"></i>
                      </a>
                    </div>
                    <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                      <img class="img-fluid" src="img/carousel-4.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* <!-- Carousel End --> */}
      {/* <!-- Service Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="d-flex py-5 px-4">
                <i class="fa fa-certificate fa-3x text-primary flex-shrink-0"></i>
                <div class="ps-4">
                  <h5 class="mb-3">Kaliteli Hizmet</h5>
                  <p>
                    Firmamız, uzman ekip ve dayanıklı forkliftlerle en kaliteli
                    hizmetleri sunar. Verimli işler için güvenilir, kaliteli ve
                    sürdürülebilir forklift çözümleri sunuyoruz.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="d-flex bg-light py-5 px-4">
                <i class="fa fa-users-cog fa-3x text-primary flex-shrink-0"></i>
                <div class="ps-4">
                  <h5 class="mb-3">Müşteri Memnuniyeti</h5>
                  <p>
                    Uzman ekip, güvenilir forkliftler, hızlı servis. Sizin
                    memnuniyetiniz önceliğimiz. Başarıya destek için buradayız.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="d-flex py-5 px-4">
                <i class="fa fa-tools fa-3x text-primary flex-shrink-0"></i>
                <div class="ps-4">
                  <h5 class="mb-3">Modern Ekipman</h5>
                  <p>
                    İleri teknolojiyle donatılmış ekipmanlarımızla verimli
                    çözümler sunuyoruz. Hızlı, güvenli ve etkili işler için
                    buradayız.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      {/* <!-- About Start --> */}
      <div class="container-xxl py-5" id="about-us">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
              <div
                class="position-relative h-100 wow fadeIn"
                data-wow-delay="0.1s"
              >
                <img
                  class="position-absolute img-fluid w-100 h-100 objCover"
                  src="img/about.png"
                  alt=""
                />
                <div
                  class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5"
                  style={{ background: "rgba(0, 0, 0, .08)" }}
                >
                  <h1 class="display-4 text-white mb-0">
                    10 <span class="fs-4">Yıllık</span>
                  </h1>
                  <h4 class="text-white">Tecrübe</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <h6 class="text-primary text-uppercase">// HAKKIMIZDA //</h6>
              <h1 class="mb-4">
                <span class="text-primary">MK İSTİF</span> Hakkında ...
              </h1>
              <p class="mb-4">
                MK İstif, 10 yıllık deneyimi ve sektördeki güçlü konumuyla,
                forklift ve istifleme araçları satışı ve servisi alanında öncü
                bir firma olarak hizmet vermektedir. Müşteri odaklı yaklaşımımız
                ve sürekli gelişen teknolojiye uyum sağlama becerimiz sayesinde,
                işletmelerin lojistik ve depolama ihtiyaçlarını karşılamak için
                en uygun çözümleri sunuyoruz. <br />
                Hizmetlerimiz arasında, yeni ve ikinci el forklift satışı,
                istifleme araçları satışı, periyodik bakım ve onarım hizmetleri,
                yedek parça temini ve uzman teknik destek bulunmaktadır. Tüm bu
                hizmetlerimizi, müşterilerimizin beklentilerini aşan bir kalite
                anlayışıyla sunarak, sektörde güvenilir ve tercih edilen bir
                partner olmayı başardık.
              </p>
              <div class="row g-4 mb-3 pb-3">
                <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                  <div class="d-flex" style={{ backgroundColor: "#DCC40C;" }}>
                    <div
                      class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <span class="fw-bold text-secondary">01</span>
                    </div>
                    <div
                      class="ps-3 d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ paddingLeft: "5px" }}
                    >
                      <h6>Yeni ve İkinci El Forklift Satışı</h6>
                    </div>
                  </div>
                </div>
                <div class="col-11 wow fadeIn" data-wow-delay="0.3s">
                  <div class="d-flex" style={{ backgroundColor: "#DCC40C;" }}>
                    <div
                      class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <span class="fw-bold text-secondary">02</span>
                    </div>
                    <div
                      class="ps-3 d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ paddingLeft: "5px" }}
                    >
                      <h6>İstifleme Araçları Satışı</h6>
                    </div>
                  </div>
                </div>
                <div class="col-10 wow fadeIn" data-wow-delay="0.5s">
                  <div class="d-flex" style={{ backgroundColor: "#DCC40C;" }}>
                    <div
                      class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <span class="fw-bold text-secondary">03</span>
                    </div>
                    <div
                      class="ps-3 d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ paddingLeft: "5px" }}
                    >
                      <h6>Periyodik Bakım ve Onarım Hizmetleri</h6>
                    </div>
                  </div>
                </div>
                <div class="col-9 wow fadeIn" data-wow-delay="0.5s">
                  <div class="d-flex" style={{ backgroundColor: "#DCC40C;" }}>
                    <div
                      class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <span class="fw-bold text-secondary">04</span>
                    </div>
                    <div
                      class="ps-3 d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ paddingLeft: "5px" }}
                    >
                      <h6>Yedek Parça Temini ve Uzman Teknik Destek</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Fact Start --> */}
      <div class="container-fluid my-5 wow fadeInUp" data-wow-delay="0.1s">
        <section class="us_section layout_padding-bottom py-5">
          <div class="container">
            <div class="us_container">
              <div class="box">
                <div class="img2-box">
                  <img src="img/animasyon-1.png" alt="" />
                </div>
                <div class="detail-box">
                  <h6></h6>
                </div>
              </div>
              <div class="box">
                <div class="img2-box">
                  <img src="img/animasyon-2.png" alt="" />
                </div>
                <div class="detail-box">
                  <h6></h6>
                </div>
              </div>
              <div class="box">
                <div class="img2-box">
                  <img src="img/animasyon-3.png" alt="" />
                </div>
                <div class="detail-box">
                  <h6></h6>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- Fact End --> */}

      {/* <!-- Service Start --> */}
      <div class="container-xxl service py-5">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="text-primary text-uppercase">HİZMETLERİMİZ</h6>
            <h1 class="mb-5">Hizmetlerimizi Keşfedin !</h1>
          </div>
          <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
            <div class="col-lg-4">
              <div class="nav w-100 nav-pills me-4">
                <button
                  class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 active"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-1"
                  type="button"
                >
                  <h4 class="m-0 font18p">Yeni ve İkinci El Forklift Satışı</h4>
                </button>
                <button
                  class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-2"
                  type="button"
                >
                  <h4 class="m-0 font18p">İstifleme Araçları Satışı</h4>
                </button>
                <button
                  class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-3"
                  type="button"
                >
                  <h4 class="m-0 font18p">
                    Periyodik Bakım ve Onarım Hizmetleri
                  </h4>
                </button>
                <button
                  class="nav-link w-100 d-flex align-items-center text-start p-4 mb-0"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-4"
                  type="button"
                >
                  <h4 class="m-0 font18p">
                    Yedek Parça Temini ve Uzman Teknik Destek
                  </h4>
                </button>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="tab-content w-100">
                <div class="tab-pane fade show active" id="tab-pane-1">
                  <div class="row g-4">
                    <div class="col-md-6 minH350p">
                      <div class="position-relative h-100">
                        <img
                          class="position-absolute img-fluid w-100 h-100 objCover"
                          src="img/service-1.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h3 class="mb-3">Yeni ve İkinci El Forklift Satışı</h3>
                      <p class="mb-4">
                        Sektördeki uzun yılların deneyimi sayesinde,
                        müşterilerimize doğru forklift seçimini yapmalarında
                        yardımcı oluyoruz. Yeni veya ikinci el tüm markalardaki
                        forkliftler için uygun fiyatlar sunarken aynı zamanda
                        teknik servis ve yedek parça desteği de sağlamaktayız.
                        İşletmenizin ihtiyaçlarına göre en uygun çözümleri
                        bulmak için bize başvurabilirsiniz.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab-pane-2">
                  <div class="row g-4">
                    <div class="col-md-6 minH350p">
                      <div class="position-relative h-100">
                        <img
                          class="position-absolute img-fluid w-100 h-100 objCover"
                          src="img/service-2.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h3 class="mb-3">İstifleme Araçları Satışı</h3>
                      <p class="mb-4">
                        İşletmelerin depolama ve taşıma ihtiyaçlarına yönelik
                        istifleme araçları satışı hizmeti, işletmemizin sunduğu
                        bir diğer hizmettir. Yeni veya ikinci el tüm
                        markalardaki istifleyiciler, paletler ve diğer
                        ekipmanlar için uygun fiyatlar sunarken aynı zamanda
                        teknik servis ve yedek parça desteği de sağlamaktayız.
                        İşletmenizin ihtiyaçlarına göre en uygun çözümleri
                        bulmak için bize başvurabilirsiniz.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab-pane-3">
                  <div class="row g-4">
                    <div class="col-md-6 minH350p">
                      <div class="position-relative h-100">
                        <img
                          class="position-absolute img-fluid w-100 h-100 objCover"
                          src="img/service-3.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h3 class="mb-3">Periyodik Bakım ve Onarım Hizmetleri</h3>
                      <p class="mb-4">
                        Müşterilerimize güvenilir teknisyenler tarafından
                        sunulan profesyonel periyodik bakım ve onarım hizmetleri
                        sağlamaktayız. Aracınızın performansının en yüksek
                        seviyede kalması için rutin işlemler yaparak beklenmedik
                        duruş sürelerini azaltırız. Ayrıca arızalar mümkün olan
                        en kısa sürede giderilerek işletme maliyetlerinin artış
                        riski en aza indirilir. Bize başvurarak, araçlarınızın
                        periyodik bakımları ve gerekli onarımları konusunda
                        güvenebileceğiniz bir çözüm bulabilirsiniz.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab-pane-4">
                  <div class="row g-4">
                    <div class="col-md-6 minH350p">
                      <div class="position-relative h-100">
                        <img
                          class="position-absolute img-fluid w-100 h-100 objCover"
                          src="img/service-4.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h3 class="mb-3">
                        Yedek Parça Temini ve Uzman Teknik Destek
                      </h3>
                      <p class="mb-4">
                        Müşterilerimize yüksek kaliteli yedek parçalar sunarak
                        araçlarının sorunsuz çalışmasını sağlıyoruz. İhtiyaç
                        duyduğunuz her türlü tamirat desteği için bizimle
                        iletişime geçebilirsiniz. <br /> Ayrıca, işletmenizin
                        ihtiyaçlarına uygun en iyi çözümü bulmak için deneyimli
                        teknik ekibimizle birlikte size uzman teknik destek
                        sağlamaktayız.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      {/* <!-- Booking Start --> */}
      <div class="container-fluid bg-white wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
          <div class="row gx-5">
            <div class="col-lg-6 py-5 d-flex justify-items-center align-items-center">
              <div class="py-5 px-4 bg-primary booking1">
                <h1 class="text-secondary mb-4">KONUMUMUZ</h1>
                <p class="text-secondary mb-0">
                  Fiziksel konumumuza yan tarafta bulunan haritadan
                  ulaşabilirsiniz.
                </p>
                <br />
                <p class="text-secondary mb-0">
                  Adres: Fevzi Çakmak Mahallesi 10618. Sokak No:32 A,
                  Karatay/KONYA
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="bg-light h-100 p-5">
                <div style={{ width: "100%" }}>
                  <iframe
                    class="booking2"
                    width="100%"
                    height="400"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Fevzi%20%C3%87akmak%20Mahallesi%2010618.%20Sokak%20No:32%20A,%20Konya,%20Turkey+(MK%20%C4%B0%C5%9F%20ve%20%C4%B0stif%20Mak.)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  >
                    <a href="https://www.maps.ie/distance-area-calculator.html">
                      area maps
                    </a>
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Booking End -->     */}

      {/* <!-- Back to Top --> */}
      <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i class="bi bi-arrow-up"></i>
      </a>
    </>
  );
}
