export default function () {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        class="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-bg-2.jpg)" }}
      >
        <div class="container-fluid page-header-inner py-5">
          <div class="container text-center">
            <h1 class="display-3 text-white mb-3 animated slideInDown">
              ÜRÜNLERİMİZ
            </h1>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Service Start --> */}
      <div class="container-xxl service py-5">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="text-primary text-uppercase">// Ürünlerimiz //</h6>
            <h1 class="mb-5">Ürünlerimizi Keşfedin !</h1>
          </div>
          <div class="container-xxl py-5">
            <div class="container">
              <div class="row g-4">
                <div
                  class="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div class="team-item">
                    <div class="position-relative overflow-hidden">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                      <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h5 class="fw-bold mb-0">Full Name</h5>
                      <small>Designation</small>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div class="team-item">
                    <div class="position-relative overflow-hidden">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                      <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h5 class="fw-bold mb-0">Full Name</h5>
                      <small>Designation</small>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div class="team-item">
                    <div class="position-relative overflow-hidden">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                      <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h5 class="fw-bold mb-0">Full Name</h5>
                      <small>Designation</small>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.7s"
                >
                  <div class="team-item">
                    <div class="position-relative overflow-hidden">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                      <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h5 class="fw-bold mb-0">Full Name</h5>
                      <small>Designation</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row g-4">
                <div
                  class="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div class="team-item">
                    <div class="position-relative overflow-hidden">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                      <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h5 class="fw-bold mb-0">Full Name</h5>
                      <small>Designation</small>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div class="team-item">
                    <div class="position-relative overflow-hidden">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                      <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h5 class="fw-bold mb-0">Full Name</h5>
                      <small>Designation</small>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div class="team-item">
                    <div class="position-relative overflow-hidden">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                      <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a class="btn btn-square mx-1" href="">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h5 class="fw-bold mb-0">Full Name</h5>
                      <small>Designation</small>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.7s"
                >
                  <div class="team-item">
                    <div class="position-relative overflow-hidden">
                      <img class="img-fluid" src="img/carousel-1.png" alt="" />
                      <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                        <p>Forklift</p>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h5 class="fw-bold mb-0">Full Name</h5>
                      <small>Designation</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End -->    */}
    </>
  );
}
