import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <>
      {/* <!-- Topbar Start --> */}
      <div class="container-fluid bg-light p-0">
        <div class="row gx-0 d-none d-lg-flex">
          <div class="col-lg-7 px-5 text-start">
            <div class="h-100 d-inline-flex align-items-center py-3 me-4">
              <small class="fa fa-map-marker-alt text-primary me-2"></small>
              <small>
                Fevzi Çakmak Mahallesi 10618. Sokak No:32 A, Karatay/KONYA
              </small>
            </div>
          </div>
          <div class="col-lg-5 px-5 text-end">
            <div class="h-100 d-inline-flex align-items-center py-3 me-4">
              <small class="fa fa-phone-alt text-primary me-2"></small>
              <small>0532 230 4082</small>
            </div>
            <div class="h-100 d-inline-flex align-items-center">
              <a
                class="btn btn-sm-square bg-white text-primary me-1"
                href="https://www.facebook.com/mkistif?mibextid=ZbWKwL"
                target="_blank"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                class="btn btn-sm-square bg-white text-primary me-0"
                href="https://instagram.com/mkistif?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Topbar End --> */}

      {/* <!-- Navbar Start --> */}
      <nav class="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
        <a
          href="index.html"
          class="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <img src="img/mk-istif-logo.png" class="w-100 h-100" />
        </a>
        <button
          type="button"
          class="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto p-4 p-lg-0">
            <a
              onClick={() => navigate("/")}
              class={`nav-item nav-link hover-cursor `}
            >
              Ana Sayfa
            </a>
            <a
              onClick={() => navigate("/hakkimizda")}
              class="nav-item nav-link hover-cursor"
            >
              Hakkımızda
            </a>
            <a
              onClick={() => navigate("/urunler")}
              class="nav-item nav-link hover-cursor"
            >
              ÜRÜNLERİMİZ
            </a>
            <a
              onClick={() => navigate("/iletisim")}
              class="nav-item nav-link hover-cursor"
            >
              İLETİŞİM
            </a>
          </div>
          <a
            onClick={() => navigate("/iletisim")}
            class="btn btn-primary py-4 px-lg-5 d-none d-lg-block"
          >
            Teklif Alın<i class="fa fa-arrow-right ms-3"></i>
          </a>
        </div>
      </nav>
      {/* <!-- Navbar End --> */}
    </>
  );
}
