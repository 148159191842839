import Contact from "../pages/Contact";
import Home from "../pages/Index";
import { useRoutes } from "react-router-dom";
import Main from "../pages/Main";
import AboutUs from "../pages/AboutUs";
import Service from "../pages/Service";

export default function Router() {
  const routes = [
    {
      element: <Main />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/iletisim",
          element: <Contact />,
        },
        {
          path: "/hakkimizda",
          element: <AboutUs />,
        },
        {
          path: "/urunler",
          element: <Service />,
        },
      ],
    },
  ];

  return useRoutes(authMap(routes));
}

const authMap = (routes) =>
  routes.map((route) => {
    if (route?.children) {
      route.children = authMap(route.children);
    }
    return route;
  });
