export default function Contact() {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        class="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-bg-1.jpg)" }}
      >
        <div class="container-fluid page-header-inner py-5">
          <div class="container text-center">
            <h1 class="display-3 text-white mb-3 animated slideInDown">
              İLETİŞİM
            </h1>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Contact Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="text-primary text-uppercase">// İletişim //</h6>
            <h1 class="mb-5">Bizimle İletişime Geçin</h1>
          </div>
          <div class="row g-4">
            <div class="col-12">
              <div class="row gy-4">
                <div class="col-md-4">
                  <div class="bg-light d-flex flex-column justify-content-center p-4">
                    <h5 class="text-uppercase px-5"> E-Mail </h5>
                    <p class="m-0">
                      <i class="fa fa-envelope-open text-primary me-2"></i>
                      info@mkistif.com
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="bg-light d-flex flex-column justify-content-center p-4">
                    <h5 class="text-uppercase px-5"> GSM </h5>
                    <p class="m-0">
                      <i class="fa fa-envelope-open text-primary me-2"></i>+90
                      532 230 40 82
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="bg-light d-flex flex-column justify-content-center p-4">
                    <h5 class="text-uppercase px-4"> Telefon </h5>
                    <p class="m-0">
                      <i class="fa fa-envelope-open text-primary me-2"></i>0332
                      342 62 30
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 wow fadeIn"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px", maxHeight: "400px" }}
            >
              <iframe
                class="position-relative rounded w-100 h-100"
                src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Fevzi%20%C3%87akmak%20Mahallesi%2010618.%20Sokak%20No:32%20A,%20Konya,%20Turkey+(MK%20%C4%B0%C5%9F%20ve%20%C4%B0stif%20Mak.)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a
                  href="https://www.maps.ie/distance-area-calculator.html"
                  frameborder="0"
                  style={{ minHeight: "350px", border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                />
              </iframe>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5" style={{ paddingLeft: "20px" }}>
              <img
                class="position-relative img-fluid"
                src="img/mechanic-screw.gif"
                style={{ maxHeight: "400px" }}
                alt=""
              />
              <section class="us_section layout_padding-bottom">
                <div class="container">
                  <div class="us_container"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}

      {/* <!-- Footer Start --> */}
      <div
        class="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Adres</h4>
              <p class="mb-2">
                <i class="fa fa-map-marker-alt me-3"></i>Fevzi Çakmak Mahallesi
                10618. Sokak No:32 A, Karatay/KONYA
              </p>
              <p class="mb-2">
                <i class="fa fa-phone-alt me-3"></i>+90 532 230 40 82
              </p>
              <p class="mb-2">
                <i class="fa fa-envelope me-3"></i>info@mkistif.com
              </p>
              <div class="d-flex pt-2">
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/mkistif?mibextid=ZbWKwL"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://instagram.com/mkistif?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Çalışma Saatleri</h4>
              <h6 class="text-light">Pazartesi - Cumartesi</h6>
              <p class="mb-4">08.00 - 19.00</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}
    </>
  );
}
