export default function AboutUs() {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        class="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(../../public/img/carousel-bg-1.jpg)" }}
      >
        <div class="container-fluid page-header-inner py-5">
          <div class="container text-center">
            <h1 class="display-3 text-white mb-3 animated slideInDown">
              HAKKIMIZDA
            </h1>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Service Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="d-flex py-5 px-4">
                <i class="fa fa-certificate fa-3x text-primary flex-shrink-0"></i>
                <div class="ps-4">
                  <h5 class="mb-3">Kaliteli Hizmet</h5>
                  <p>
                    Firmamız, uzman ekip ve dayanıklı forkliftlerle en kaliteli
                    hizmetleri sunar. Verimli işler için güvenilir, kaliteli ve
                    sürdürülebilir forklift çözümleri sunuyoruz.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="d-flex bg-light py-5 px-4">
                <i class="fa fa-users-cog fa-3x text-primary flex-shrink-0"></i>
                <div class="ps-4">
                  <h5 class="mb-3">Müşteri Memnuniyeti</h5>
                  <p>
                    Uzman ekip, güvenilir forkliftler, hızlı servis. Sizin
                    memnuniyetiniz önceliğimiz. Başarıya destek için buradayız.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="d-flex py-5 px-4">
                <i class="fa fa-tools fa-3x text-primary flex-shrink-0"></i>
                <div class="ps-4">
                  <h5 class="mb-3">Modern Ekipman</h5>
                  <p>
                    İleri teknolojiyle donatılmış ekipmanlarımızla verimli
                    çözümler sunuyoruz. Hızlı, güvenli ve etkili işler için
                    buradayız.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      {/* <!-- About Start --> */}
      <div class="container-xxl py-5" id="about-us">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
              <div
                class="position-relative h-100 wow fadeIn"
                data-wow-delay="0.1s"
              >
                <img
                  class="position-absolute img-fluid w-100 h-100 objCover"
                  src="img/about.png"
                  alt=""
                />
                <div
                  class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5"
                  style={{ background: "rgba(0, 0, 0, .08)" }}
                >
                  <h1 class="display-4 text-white mb-0">
                    10 <span class="fs-4">Yıllık</span>
                  </h1>
                  <h4 class="text-white">Tecrübe</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <h6 class="text-primary text-uppercase">// HAKKIMIZDA //</h6>
              <h1 class="mb-4">
                <span class="text-primary">MK İSTİF</span> Hakkında ...
              </h1>
              <p class="mb-4">
                MK İstif, 10 yıllık deneyimi ve sektördeki güçlü konumuyla,
                forklift ve istifleme araçları satışı ve servisi alanında öncü
                bir firma olarak hizmet vermektedir. Müşteri odaklı yaklaşımımız
                ve sürekli gelişen teknolojiye uyum sağlama becerimiz sayesinde,
                işletmelerin lojistik ve depolama ihtiyaçlarını karşılamak için
                en uygun çözümleri sunuyoruz. <br />
                Hizmetlerimiz arasında, yeni ve ikinci el forklift satışı,
                istifleme araçları satışı, periyodik bakım ve onarım hizmetleri,
                yedek parça temini ve uzman teknik destek bulunmaktadır. Tüm bu
                hizmetlerimizi, müşterilerimizin beklentilerini aşan bir kalite
                anlayışıyla sunarak, sektörde güvenilir ve tercih edilen bir
                partner olmayı başardık.
              </p>
              <div class="row g-4 mb-3 pb-3">
                <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                  <div class="d-flex" style={{ backgroundColor: "#DCC40C;" }}>
                    <div
                      class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <span class="fw-bold text-secondary">01</span>
                    </div>
                    <div
                      class="ps-3 d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ paddingLeft: "5px" }}
                    >
                      <h6>Yeni ve İkinci El Forklift Satışı</h6>
                    </div>
                  </div>
                </div>
                <div class="col-11 wow fadeIn" data-wow-delay="0.3s">
                  <div class="d-flex" style={{ backgroundColor: "#DCC40C;" }}>
                    <div
                      class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <span class="fw-bold text-secondary">02</span>
                    </div>
                    <div
                      class="ps-3 d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ paddingLeft: "5px" }}
                    >
                      <h6>İstifleme Araçları Satışı</h6>
                    </div>
                  </div>
                </div>
                <div class="col-10 wow fadeIn" data-wow-delay="0.5s">
                  <div class="d-flex" style={{ backgroundColor: "#DCC40C;" }}>
                    <div
                      class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <span class="fw-bold text-secondary">03</span>
                    </div>
                    <div
                      class="ps-3 d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ paddingLeft: "5px" }}
                    >
                      <h6>Periyodik Bakım ve Onarım Hizmetleri</h6>
                    </div>
                  </div>
                </div>
                <div class="col-9 wow fadeIn" data-wow-delay="0.5s">
                  <div class="d-flex" style={{ backgroundColor: "#DCC40C;" }}>
                    <div
                      class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <span class="fw-bold text-secondary">04</span>
                    </div>
                    <div
                      class="ps-3 d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                      style={{ paddingLeft: "5px" }}
                    >
                      <h6>Yedek Parça Temini ve Uzman Teknik Destek</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}
    </>
  );
}
